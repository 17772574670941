import React, { useContext } from 'react'
import { navigate } from 'gatsby'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormCardSelection from '../../../views/custom-quote/components/FormCardSelection'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'

const CategoryPage = () => {
  const { formData, updateFormData } = useContext(CustomQuoteContext)

  const handleSelect = (item) => {
    updateFormData({
      ...formData,
      category: item,
    })
    navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.category.next)
  }

  const items = [
    {
      label: 'architecture',
    },
    {
      label: 'art sculpture',
    },
    {
      label: 'commercial display',
    },
    {
      label: 'designer toy',
    },
    {
      label: 'electronics',
    },
    {
      label: 'hobby',
    },
    {
      label: 'jewelry',
    },
    {
      label: 'prototype',
    },

    {
      label: 'other',
    },
  ]

  return (
    <CustomQuoteLayout>
      <FormQuestion title="What is the project for?" />
      <FormCardSelection
        items={items}
        onSelect={handleSelect}
        selectedValue={formData && formData.category}
      />
      <FormNavigation
        onSubmit={() => navigate(CUSTOM_QUOTE_SEQUENCE_LINKS.category.next)}
        previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.category.previous}
      />
    </CustomQuoteLayout>
  )
}

export default CategoryPage